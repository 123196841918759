@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');


label:hover {
  cursor: pointer;
}

body {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

.container {
  width:96%!important;
  margin:0 auto!important;
}

.beforeArrow::before {
 content:'▶';
 background-color:#ff0;
 border-radius:50%;
 padding:2px 10px;
 margin-right:10px;
}